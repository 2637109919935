export enum Status { 
    NEW = 1, 
    ONLINE = 2, 
    CLOSED = 3, 
    ARCHIVED = 4, 
    REFUSED = 5, 
    DELETED = 6, 
    UNVAILABLE = 7,
    SCHEDULED_UNASSIGNED = 8,
    SCHEDULED_ASSIGNED = 9,
}
