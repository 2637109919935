import { assign } from 'lodash';
import { IUserTagCategory } from '../../../../../interfaces/i-user-tag-category';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IUserTag } from '../../../../../interfaces/i-user-tag';
import { UserTagCategoryService } from '../../../../services/api/api-user-tag-category.service';

@Component({
    selector: 'fuse-dialog-user-tag',
    templateUrl: './dialog-user-tag.component.html',
    styleUrls: ['./dialog-user-tag.component.scss'],
})
export class DialogUserTagComponent implements OnInit {
    public userTagCategories: IUserTagCategory[];
    public formGroup: FormGroup;
    public title: string;
    public description: string;
    public currentCategory: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogUserTagComponent>,
        private userTagCategoryService: UserTagCategoryService
    ) {
        this.title = this.data.title;
        this.description = this.data.data ? this.data.data.name : '';
        this.currentCategory = this.data.data ? this.data.data.id_category : '';
    }

    ngOnInit(): void {
        this.userTagCategoryService.apiGetUserTagCategories().subscribe((data) => {
            this.userTagCategories = data;
        });

        this.formGroup = new FormGroup({
            name: new FormControl(this.description, [Validators.required]),
            id_category: new FormControl(this.currentCategory, [Validators.required]),
        });
    }

    public action() {
        const modalData: IUserTag = assign({}, this.formGroup.value);
        if (this.data.data) {
            modalData.id = this.data.data.id;
        }

        this.dialogRef.close(modalData);
    }
}
