import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { SharedModule } from "../../../../core/modules/shared.module";
import {
  SimpleNotificationsModule,
  NotificationsService,
} from "angular2-notifications";
import { ReactiveFormsModule } from "@angular/forms";
import { CommunicationsComponent } from "./communications.component";
import { CreateCommunicationComponent } from "./create-communication/create-communication.component";
import { CommunicationService } from "../../../services/api/api-communication.service";

const routes: Routes = [
  {
    path: "pages/communications",
    component: CommunicationsComponent,
  },
  {
    path: "pages/communications/create",
    component: CreateCommunicationComponent,
  },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    SimpleNotificationsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  declarations: [CommunicationsComponent, CreateCommunicationComponent],
  exports: [CommunicationsComponent],
  entryComponents: [],
  providers: [CommunicationService],
})
export class CommunicationsModule {}
