import { IUserTagCategory } from './../../../interfaces/i-user-tag-category';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetBaseUrl } from '../helper/getBaseUrl';

@Injectable()
export class UserTagCategoryService {
    private baseUrl = GetBaseUrl.baseUrl();

    constructor(private http: HttpClient) {}

    public apiGetUserTagCategories(): Observable<IUserTagCategory[]> {
        return this.http.get<IUserTagCategory[]>(`${this.baseUrl}/user-tag-category`);
    }
}
