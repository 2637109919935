import { UserTagCategoryService } from './../../../services/api/api-user-tag-category.service';
import { UserTagService } from './../../../services/api/api-user-tag.service';
import { UserTagComponent } from './user-tag.component';
import { UtilsModule } from './../utils/utils.module';
import { DialogConfirmComponent } from './../utils/dialog-confirm/dialog-confirm.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../../core/modules/shared.module';
import { DialogUserTagComponent } from './dialog-user-tag/dialog-user-tag.component';

const routes: Routes = [
    {
        path: 'pages/user-tags',
        component: UserTagComponent,
    },
];

@NgModule({
    declarations: [UserTagComponent, DialogUserTagComponent],
    imports: [UtilsModule, SharedModule, RouterModule.forChild(routes)],
    entryComponents: [DialogConfirmComponent, DialogUserTagComponent],
    providers: [UserTagService, UserTagCategoryService],
})
export class UserTagModule {}
