export enum TicketCategory {
  SALUTE = 1,
  PUBBLICA_AMMINISTRAZIONE = 2,
  LAVORO = 3,
  SCUOLA_UNIVERSITA = 4,
  BANCA_POSTE = 5,
  COMMERCIALISTA_CAF = 6,
  SINDACATO = 7,
  ASSICURAZIONE = 8,
  URGENZA_SANITA = 9,
  ALTRO = 10,
}
