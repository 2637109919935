import { assign } from "lodash";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IUserTag } from "../../../../../interfaces/i-user-tag";
import { UserTagService } from "../../../../services/api/api-user-tag.service";
import { MatDialog } from "@angular/material";
import { SearchUserDialogComponent } from "../../sending-sms/search-user-dialog/search-user-dialog.component";
import { IUserDataResponse } from "../../../../../interfaces/i-userdata.request";
import { DialogConfirmComponent } from "../../utils/dialog-confirm/dialog-confirm.component";
import { ErrorMessageTranslatorService } from "../../../../services/error-message-translator.service";
import { NotificationsService } from "angular2-notifications";
import { CommunicationService } from "../../../../services/api/api-communication.service";
import { Router } from "@angular/router";
import * as _ from 'lodash';
import { filter } from "rxjs/operators";
import { AuthService } from "../../../../services/auth/auth.service";

@Component({
  selector: "fuse-create-communication",
  templateUrl: "./create-communication.component.html",
  styleUrls: ["./create-communication.component.scss"],
})
export class CreateCommunicationComponent implements OnInit {
  public formGroup: FormGroup;
  public userTags: IUserTag[];
  public userTagsForRequest: number[] = [];
  public usersLength: number = 0;
  public dataForBind: any[] = [
    { id: 0, title: "Aggiungi nome", content: "{{name}}" },
    { id: 1, title: "Aggiungi cognome", content: "{{surname}}" },
  ];
  public managerUserTag: boolean = false;

  @ViewChild("inputElement") inputElement: ElementRef;

  constructor(
    private fb: FormBuilder,
    private userTagService: UserTagService,
    public dialog: MatDialog,
    private errorMessageTranslatorService: ErrorMessageTranslatorService,
    public toast: NotificationsService,
    private communicationService: CommunicationService,
    private router: Router,
    private authService: AuthService
  ) {
    this.managerUserTag = this.authService.hasPermission(['manage.usertag']);

    this.formGroup = new FormGroup({
      tipology: new FormControl("2", [Validators.required]),
      title: new FormControl("", [Validators.required]),
      message: new FormControl("", [Validators.required]),
    });
    
    
  }

  ngOnInit() {
    this.changeTipology();
    if(this.managerUserTag){
      this._addUserTagsControl();
    
      this.userTagService
        .apiGetUserTags({ paginated: false })
        .subscribe((data) => {
          this.userTags = data;
        });
    }
  }

  private _addUserTagsControl(): void {
    this.formGroup.addControl(
      "id_user_tags",
      new FormControl(null, [Validators.required])
    );
  }

  public changeTipology() {
    if(this.formGroup && this.formGroup.get("tipology").value === "0"){
      this.formGroup.removeControl("id_user_tags");
      this.formGroup.removeControl("allUsers")
      this.formGroup.addControl("usersInputs", this.fb.array([]));
      this.addUserInput();
    } else if (this.formGroup && this.formGroup.get("tipology").value === "1") {
      this.formGroup.removeControl("usersInputs");
      this.formGroup.removeControl("allUsers")
      this._addUserTagsControl();
    } else if (this.formGroup && this.formGroup.get("tipology").value === "2"){
      this.formGroup.removeControl("usersInputs");
      this.formGroup.removeControl("id_user_tags");
      this.formGroup.addControl("allUsers", new FormControl(true))
    }
    this.formGroup.updateValueAndValidity();
  }

  searchUser(index: number) {
    this.dialog
      .open(SearchUserDialogComponent, {
        hasBackdrop: true,
        panelClass: "confirm-panel",
      })
      .afterClosed()
      .subscribe((data: IUserDataResponse) => {
        if (data) {
          const newValue = this.usersInputs.controls[index];
          newValue.patchValue({
            id_userdata: data.id,
            name_surname: `${data.name} ${data.surname}`,
          });
        }
      });
  }

  get usersInputs() {
    return this.formGroup.get("usersInputs") as FormArray;
  }

  addUserInput() {
    this.usersInputs.push(
      this.fb.group({
        id_userdata: new FormControl({ value: null, disabled: true }, [
          Validators.required,
        ]),
        name_surname: new FormControl({ value: null, disabled: true }, [
          Validators.required,
        ]),
      })
    );
  }

  deleteUserInput(index: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        id: index,
        title: "Cancellare utente?",
        caption: "",
        action: "Cancella",
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => !!result)
      )
      .subscribe((data) => this.usersInputs.removeAt(index));
  }

  onSelectionChange(event: any) {
    const selectedOption = event.value;
    const currentText = this.formGroup.get("message").value;
    const newText = currentText + selectedOption;
    this.formGroup.get("message").setValue(newText);
  }

  onSubmit() {
    const payload = assign({}, this.formGroup.value);
    if (this.formGroup.controls.usersInputs) {
      payload.users = this.formGroup.controls.usersInputs.value;
    }

    if (this.formGroup.valid) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          id: this.formGroup.value,
          title: "Invio",
          caption:
            "Non si potrà interrompere il processo di invio. Si vuole procedere ugualmente?",
          action: "Invia",
        },
      });

      dialogRef
        .afterClosed()
        .pipe(
          filter((result) => !!result)
        )
        .subscribe(
          (result) => {
            this.communicationService.apiCreateCommunication(payload).subscribe(
              (result) => {
                this.toast.success(
                  "Gestione delle comunicazioni",
                  "Inviando..."
                );
                this.router.navigate(["pages/communications"]);
              },
              (err) => {
                this.toast.error(
                  "Gestione delle comunicazioni",
                  this.errorMessageTranslatorService.translate(
                    err.error.message
                  )
                );
              }
            );
          },
          (err) => {
            this.toast.error(
              "Gestione delle comunicazioni",
              this.errorMessageTranslatorService.translate(err.error.message)
            );
          }
        );
    }
  }
}
