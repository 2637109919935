import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ITicket } from "../../../../../interfaces/i-ticket";
import { TicketCategory } from "../../../../../enums/ticket-category.enum";

@Component({
  selector: "fuse-ticket-item",
  templateUrl: "./ticket-item.component.html",
  styleUrls: ["./ticket-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketItemComponent implements OnInit {
  @Input() allTicket: Observable<ITicket[]>;
  public dataSource: MatTableDataSource<ITicket>;
  public dataBadge: number[] = [];
  public emergencyCategory: number = TicketCategory.URGENZA_SANITA;

  @Input("hidePaginator") hidePaginator: boolean = false;
  @Input("hideFilter") hideFilter: boolean = false;
  @Input("hideActions") hideActions: boolean = false;

  public displayedColumns = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private cd: ChangeDetectorRef, private router: Router) {}

  ngOnInit() {
    this.allTicket.subscribe((ticket: ITicket[]) => {
      this.dataSource = new MatTableDataSource(ticket);
      this.cd.markForCheck();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.displayedColumns = [
      "id",
      "status",
      "schedule_start",
      "duration",
      "category",
      "operator",
      "user",
      "region",
      "typology",
      "mode",
    ].concat(!this.hideActions ? ["action"] : []);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  showTicketDetail(item: ITicket) {
    this.router.navigate(["pages/ticket-detail", item.id]);
  }
}
