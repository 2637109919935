import { CalendarDateFormatter, CalendarModule, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale,{
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale,{
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

  
}