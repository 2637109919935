export const environment = {
    production: true,
    hmr: false,
    nav_title: 'Ermes',
    login_title: 'Benvenuti in Ermes!',
    beep_alarm : 'beep.wav',
    beep_sos: 'beep_SOS.wav',

    // Socket.io configuration url
    ws_url: 'https://saittms.3punto6.com',
    ws_path: '/wss',
    ws_port: null,

    // API configuration url
    api_url: 'https://saittms.3punto6.com',
    api_port: null,
    api_suffix: '/api',

    // App Config
    APP_TICKET_RETENTION_DAY: 2,
    HANDLE_ERROR: false,
    rollbar: {
        accessToken: '',
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: ''
    }
};
