import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector     : 'fuse-navbar-horizontal',
    templateUrl  : './navbar-horizontal.component.html',
    styleUrls    : ['./navbar-horizontal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavbarHorizontalComponent implements OnInit
{
    constructor()
    {
    }

    ngOnInit()
    {

    }
}
