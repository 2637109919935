import { NgModule } from '@angular/core';
import { SharedModule } from "../../../core/modules/shared.module";
import { Login2Module } from './authentication/login-2/login-2.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ScheduleManagerModule } from './schedule-manager/schedule-manager.module';
import { SendingSmsModule } from './sending-sms/sending-sms.module';
import { StatisticsModule } from './statistics/statistics.module';
import { TicketDetailModule } from './ticket-detail/ticket-detail.module';
import { UserManagerModule } from './user-manager/user-manager.module';
import { UserTagModule } from './user-tag/user-tag.module';
import { UtilsModule } from './utils/utils.module';
import { CommunicationsModule } from './communications/communications.module';

@NgModule({
    imports: [
      Login2Module,
      DashboardModule,
      TicketDetailModule,
      SendingSmsModule,
      SharedModule,
      UserManagerModule,
      StatisticsModule,
      ScheduleManagerModule,
      UtilsModule,
      UserTagModule,
      CommunicationsModule
    ],
    declarations: [
    ]
})
export class PagesModule
{
}
