import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GetBaseUrl } from "../helper/getBaseUrl";
import { ICommunicationRequest } from "../../../interfaces/i-communication-request";
import { IPageRequest } from "../../../interfaces/i-page-request";
import { UtilsService } from "./utils.service";
import { ICommunicationsResponse } from "../../../interfaces/i-communications-response";

@Injectable()
export class CommunicationService {
  private baseUrl = GetBaseUrl.baseUrl();

  constructor(private http: HttpClient) {}

  public apiCreateCommunication(
    communication: ICommunicationRequest
  ): Observable<ICommunicationRequest> {
    return this.http.post<ICommunicationRequest>(
      this.baseUrl + "/communication",
      communication
    );
  }

  public apiGetCommunications(
    request: IPageRequest
  ): Observable<ICommunicationsResponse> {
    let queryParams: HttpParams = UtilsService.buildQueryParams(request);

    return this.http.get<ICommunicationsResponse>(
      `${this.baseUrl}/communication`,
      {
        params: queryParams,
      }
    );
  }
}
