import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiTicketReportService } from '../../../../../services/api/api-ticket-report.service';

@Component({
  selector: 'fuse-dialog-close-ticket',
  templateUrl: './dialog-close-ticket.html',
  styleUrls: ['../ticket-head.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class DialogCloseTicket {
  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCloseTicket>,
    private apiTicketReportService: ApiTicketReportService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroup = new FormGroup({
      note: new FormControl(undefined)
    });
  }
  async onYesClick() {
    const note = this.formGroup.get('note').value;
    if (note) {
      this.apiTicketReportService.create({
        id_ticket: this.data.ticket.id,
        note: note
      }).subscribe(() => {
        this.dialogRef.close('true');
      });
    } else {
      this.dialogRef.close('true')
    }
  }
}

