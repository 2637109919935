import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../../../../core/modules/shared.module';
import { AuthGuard } from '../../../../guard/auth.guard';
import { IsOperatorGuard } from '../../../../guard/is-operator.guard';
import { ChatService } from '../../../services/ticket-messages/ticket-messages.service';
import { ToastMessage } from '../../../services/toastMessage.service';
import { TicketDetailComponent } from './ticket-detail.component';
import { DialogCloseTicket } from './ticket-head/dialog-component/dialog-close.component';
import { DialogDetail } from './ticket-head/dialog-component/dialog-detail.component';
import { TicketHeadComponent } from './ticket-head/ticket-head.component';
import { TicketMessagesComponent } from './ticket-messages/ticket-messages.component';
import { TicketScheduledComponent } from './ticket-scheduled/ticket-scheduled.component';
import { TicketVideochatComponent } from './ticket-videochat/ticket-videochat.component';

const routes: Routes = [
    {
        path     : 'pages/ticket-detail/:id',
        canActivate: [AuthGuard, IsOperatorGuard],
        component: TicketDetailComponent
    }
];


@NgModule({
  declarations: [
    TicketDetailComponent,
    TicketHeadComponent,
    TicketMessagesComponent,
    TicketScheduledComponent,
    DialogCloseTicket,
    DialogDetail,
    TicketVideochatComponent,
  ],
  entryComponents: [
    DialogCloseTicket,
    DialogDetail
  ],
  imports: [
    SharedModule,
    NgxSpinnerModule,
    RouterModule.forChild(routes),
    SimpleNotificationsModule,
    ReactiveFormsModule
  ],
  exports: [
    TicketDetailComponent,
  ],
  providers: [
    ChatService,
    ToastMessage,
  ]
})

export class TicketDetailModule { }
