import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'fuse-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent implements OnInit {
    public title: string;
    public caption: string;
    public actionType: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DialogConfirmComponent>) {}

    ngOnInit(): void {
        this.title = this.data.title;
        this.caption = this.data.caption;
        this.actionType = this.data.action;
    }

    public action() {
        this.dialogRef.close(this.data.id);
    }
}
