import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../core/modules/shared.module';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';

@NgModule({
    declarations: [DialogConfirmComponent],
    imports: [SharedModule],
    exports: [DialogConfirmComponent],
})
export class UtilsModule {}
