
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../main/services/auth/auth.service';
import { LocalStorageService } from '../main/services/local-storage/local-storage.service';



import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private storage: LocalStorageService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken().token_session}`
        }
      });
      return next
              .handle(request).pipe(
              catchError(response => {
                if (response.status === 401) {
                  this.storage.clear();
                  this.spinner.hide();
                  this.router.navigate(['pages/authentication/login-2']);
                }
                return observableThrowError(response);
              }));
    }
    return next.handle(request);
  }
}
