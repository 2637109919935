import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CommunicationService } from "../../../services/api/api-communication.service";
import { Page } from "../../../../class/page";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { distinctUntilChanged, map } from "rxjs/operators";

@Component({
  selector: "fuse-communications",
  templateUrl: "./communications.component.html",
  styleUrls: ["./communications.component.scss"],
})
export class CommunicationsComponent implements OnInit, OnDestroy {
  public rows: any[] = [];
  public page = new Page();
  public pageSizeControl: FormControl;
  private pageSizeControlSubscription: Subscription;

  @ViewChild("myTable") table;

  constructor(
    private router: Router,
    private communicationService: CommunicationService,
    private spinner: NgxSpinnerService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.pageSizeControl = new FormControl(10);

    this.pageSizeControlSubscription = this.pageSizeControl.valueChanges
      .pipe(map(Number), distinctUntilChanged())
      .subscribe((data) => {
        this.setPage({ offset: 0 });
      });
  }

  ngOnDestroy(): void {
    if (this.pageSizeControlSubscription) {
      this.pageSizeControlSubscription.unsubscribe();
    }
  }

  create() {
    this.router.navigate(["pages/communications/create"]);
  }

  setPage(pageInfo) {
    this.spinner.show();

    this.page.pageNumber = pageInfo.offset;
    this.page.size = this.pageSizeControl.value;

    this.communicationService.apiGetCommunications(this.page).subscribe(
      (pagedData) => {
        this.page = pagedData.page;
        this.rows = pagedData.data;
        this.spinner.hide();
      },
      () => this.spinner.hide()
    );
  }
}
