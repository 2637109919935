import { environment } from "./../../../../../environments/environment";
import { TicketCategory } from "./../../../../enums/ticket-category.enum";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { BehaviorSubject, merge as observableMerge, Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { Services } from "../../../../enums/ticket-services.enum";
import { Status } from "../../../../enums/ticket-status.enum";
import { ITicket } from "../../../../interfaces/i-ticket";
import { WsEvents } from "../../../../type/ws-events";
import { ApiTicketService } from "../../../services/api/api-ticket.service";
import { LocalStorageService } from "../../../services/local-storage/local-storage.service";
import { SocketService } from "../../../services/socket/socket.service";
import { NotificationsService } from "angular2-notifications";

@Component({
  selector: "fuse-ticket-detail",
  templateUrl: "./ticket-detail.component.html",
  styleUrls: ["./ticket-detail.component.scss"],
})
export class TicketDetailComponent implements OnInit, OnDestroy {
  public idTicket: number;
  public ticket = new BehaviorSubject<ITicket>(null);
  public isVideochat = false;
  public isScheduledUnassigned = false;
  public open = false;
  public isManagedByOperator = false;
  public user;
  public beep;
  private updatingTicketSubscription: Subscription;
  private messageCreatedSubscription: Subscription;
  private newTicketSubscription: Subscription;
  private lastTicket: ITicket;

  constructor(
    private route: ActivatedRoute,
    private storage: LocalStorageService,
    private apiTicket: ApiTicketService,
    private socketService: SocketService,
    private toast: NotificationsService
  ) {
    // tslint:disable-next-line:radix
    this.idTicket = parseInt(this.route.snapshot.paramMap.get("id"));
    this.user = this.storage.getItem("user");
    this.beep = new Audio("/assets/audio/" + environment.beep_sos);
  }

  ngOnInit() {
    this.messageCreatedSubscription = this.socketService
      .getMessage(WsEvents.ticketHistory.create)
      .pipe(
        debounceTime(500),
        filter((data: ITicket) => data.id === this.idTicket)
      )
      .subscribe((data: ITicket) => {
        this.ticket.next(_.assign({}, this.lastTicket, data));
      });

    this.updatingTicketSubscription = observableMerge(
      this.apiTicket.getFromId(this.idTicket),
      this.socketService.getMessage<ITicket>(WsEvents.ticket.updated)
    )
      .pipe(filter((data: ITicket) => data.id === this.idTicket))
      .subscribe((data: ITicket) => {
        this.lastTicket = _.assign({}, this.lastTicket || {}, data);
        this.ticket.next(this.lastTicket);
        this.isVideochat = _.includes(
          [Services.VIDEOCHAT, Services.VIDEOCHAT_SCHEDULED],
          this.lastTicket.id_service
        );
        this.isScheduledUnassigned = _.includes(
          [Status.SCHEDULED_UNASSIGNED],
          this.lastTicket.id_status
        );
        this.open = _.includes(
          [Status.ONLINE, Status.REFUSED, Status.CLOSED],
          this.lastTicket.id_status
        );
        this.isManagedByOperator = this.lastTicket.id_operator === this.user.id;
      });

    this.newTicketSubscription = this.socketService
      .getMessage(WsEvents.ticket.create)
      .subscribe((data: ITicket) => {
        if (data.id_category === TicketCategory.URGENZA_SANITA) {
          this.beep.load();
          this.beep.play();
        }
      });
  }

  ngOnDestroy() {
    if(this.beep) {
      this.beep.pause();
      this.beep = null;  
    }
    if (this.updatingTicketSubscription) {
      this.updatingTicketSubscription.unsubscribe();
    }
    if (this.messageCreatedSubscription) {
      this.messageCreatedSubscription.unsubscribe();
    }
    if (this.newTicketSubscription) {
      this.newTicketSubscription.unsubscribe();
    }
  }

  setOpen($event) {
    this.open = $event;
  }
}
