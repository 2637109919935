import { TicketCategory } from "./../../../../enums/ticket-category.enum";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material";
import { NotificationsService } from "angular2-notifications";
import * as _ from "lodash";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { Services } from "../../../../enums/ticket-services.enum";
import { Status } from "../../../../enums/ticket-status.enum";
import { ITicket } from "../../../../interfaces/i-ticket";
import { AuthService } from "../../../../main/services/auth/auth.service";
import { WsEvents } from "../../../../type/ws-events";
import { ApiTicketService } from "../../../services/api/api-ticket.service";
import {
  NormalizedTicket,
  NormalizeTicket,
} from "../../../services/helper/normalize-ticket";
import { LocalStorageService } from "../../../services/local-storage/local-storage.service";
import { SocketService } from "../../../services/socket/socket.service";

@Component({
  selector: "fuse-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild("tabGroup") tabGroup: any;
  private idOperator: number;
  public totalBadge = 0;
  public beep;
  public currentTabIndex: number;
  public tabChangedSubject: BehaviorSubject<number>;
  public tableTickets: Subject<NormalizedTicket[]> = new Subject();
  public currentStatus: Status = Status.NEW;
  private currentStatusId = 0;

  private MINE_TICKETS_TAB = 4;
  private newTicketSubscription: Subscription;
  private tabChangedSubscription: Subscription;
  private newHistoryTicketSubscription: Subscription;
  private updatingTicketSubscription: Subscription;
  private permissionScheduled: boolean;
  onlyScheduledRequest: boolean = false;

  constructor(
    private apiTicket: ApiTicketService,
    private storage: LocalStorageService,
    private socketService: SocketService,
    private toast: NotificationsService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) {
    this.idOperator = this.storage.getItem("user").id;
    this.currentTabIndex =
      parseInt(this.storage.getKey("dashboard_selected_tabindex"), 10) || 0;
    this.tabChangedSubject = new BehaviorSubject<number>(this.currentTabIndex);
  }

  ngOnInit() {
    this.permissionScheduled = this.authService.hasPermission([
      "ticket.scheduled.view.all",
    ]);

    if (this.currentTabIndex) {
      this.tabGroup.selectedIndex = this.currentTabIndex;
    }

    this.tabChangedSubscription = this.tabChangedSubject
      .pipe(
        tap(() => this.spinner.show()),
        mergeMap((status: number) => this.loadTicketsWith(status)),
        map((tickets: ITicket[]) => NormalizeTicket.normalizeItems(tickets)),
        tap((tickets: NormalizedTicket[]) => {
          this.tableTickets.next(tickets);
          this.spinner.hide();
        })
      )
      .subscribe();

    this.newTicketSubscription = this.socketService
      .getMessage(WsEvents.ticket.create)
      .subscribe((data: ITicket) => {
        if (
          [Status.SCHEDULED_UNASSIGNED, Status.NEW].includes(this.currentStatus)
        ) {
          this.tabChangedSubject.next(this.currentTabIndex);
        }

        const newUserSurname = _.get(data, "user.userdata.surname", null)
          ? data.user.userdata.surname
          : "Unknown";
        const message = this.toast.info(
          "Nuovo Ticket!",
          "Nuovo ticket da " + newUserSurname
        );
        message.click.subscribe((event) => {
          this.tabGroup.selectedIndex = 0;
        });

        if (data.id_category === TicketCategory.URGENZA_SANITA) {
          this.beep = new Audio("/assets/audio/" + environment.beep_sos);
        } else {
          this.beep = new Audio("/assets/audio/" + environment.beep_alarm);
        }
        this.beep.load();
        this.beep.play();
      });

    this.newHistoryTicketSubscription = this.socketService
      .getMessage(WsEvents.ticketHistory.create)
      .subscribe((ticket: ITicket) => {
        if (
          ticket.id_operator === this.idOperator &&
          this.currentTabIndex === this.MINE_TICKETS_TAB
        ) {
          this.tabChangedSubject.next(this.currentTabIndex);
        }
      });

    this.updatingTicketSubscription = this.socketService
      .getMessage(WsEvents.ticket.updated)
      .subscribe((ticket: ITicket) => {
        if (
          ticket.id_operator !== this.idOperator &&
          this.currentTabIndex === this.MINE_TICKETS_TAB
        ) {
          return;
        }
        this.tabChangedSubject.next(this.currentTabIndex);
      });
  }

  togleScheduledRequest(event) {
    this.onlyScheduledRequest = !!event.checked;
    this.tabChangedSubject.next(this.currentStatusId);
  }

  ngOnDestroy(): void {
    if(this.beep) {
      this.beep.pause();
      this.beep = null;  
    }
    if (this.newTicketSubscription) {
      this.newTicketSubscription.unsubscribe();
    }
    if (this.tabChangedSubscription) {
      this.tabChangedSubscription.unsubscribe();
    }
    if (this.newHistoryTicketSubscription) {
      this.newHistoryTicketSubscription.unsubscribe();
    }
    if (this.updatingTicketSubscription) {
      this.updatingTicketSubscription.unsubscribe();
    }
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.storage.setItem(
      "dashboard_selected_tabindex",
      tabChangeEvent.index.toString()
    );
    this.currentTabIndex = tabChangeEvent.index;
    this.tabChangedSubject.next(tabChangeEvent.index);
  };

  private loadTicketsWith(statusId: number): Observable<ITicket[]> {
    const tabsToStatus: Status[] = [
      Status.NEW,
      Status.ONLINE,
      Status.CLOSED,
      Status.REFUSED,
      Status.ONLINE,
      Status.SCHEDULED_ASSIGNED,
      Status.SCHEDULED_UNASSIGNED,
    ];
    this.currentStatus = tabsToStatus[statusId];
    this.currentStatusId = statusId;

    const serviceLimit = this.onlyScheduledRequest
      ? { id_service: Services.VIDEOCHAT_SCHEDULED.toString() }
      : null;

    const limitOperatorIfTicketAssigned =
      !this.permissionScheduled &&
      this.currentStatus === Status.SCHEDULED_ASSIGNED
        ? {
            id_user: this.idOperator.toString(),
          }
        : null;

    if (statusId === this.MINE_TICKETS_TAB) {
      return this.apiTicket.getWithCriterias(
        Object.assign(
          {
            mapped: "0",
            id_status: this.currentStatus.toString(),
            id_user: this.idOperator.toString(),
          },
          serviceLimit
        )
      );
    }

    return this.apiTicket.getWithCriterias(
      Object.assign(
        {
          mapped:
            this.currentStatus === Status.SCHEDULED_ASSIGNED ||
            this.currentStatus === Status.SCHEDULED_UNASSIGNED
              ? "0"
              : environment.APP_TICKET_RETENTION_DAY.toString(),
          id_status: this.currentStatus.toString(),
        },
        serviceLimit,
        limitOperatorIfTicketAssigned
      )
    );
  }
}
