
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { assign, find } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { filter, flatMap, map, mergeMap, tap } from "rxjs/operators";
import swal from 'sweetalert2';
import { HistoryTypes } from '../../../../../enums/ticket-history-type.enum';
import { Status } from '../../../../../enums/ticket-status.enum';
import { ITicket } from '../../../../../interfaces/i-ticket';
import { ITicketHistory } from '../../../../../interfaces/i-ticket-history';
import { ApiTicketHistoryService } from '../../../../services/api/api-ticket-history.service';
import { ApiTicketService } from '../../../../services/api/api-ticket.service';
import { AuthService } from "../../../../services/auth/auth.service";
import { NormalizedTicket, NormalizeTicket } from "../../../../services/helper/normalize-ticket";
import { LocalStorageService } from '../../../../services/local-storage/local-storage.service';
import { ToastMessage } from '../../../../services/toastMessage.service';
import { DialogCloseTicket } from "./dialog-component/dialog-close.component";


@Component({
  selector: 'fuse-ticket-head',
  templateUrl: './ticket-head.component.html',
  styleUrls: ['./ticket-head.component.scss']
})
export class TicketHeadComponent implements OnInit, OnDestroy {
  private interval;

  @Input('ticket') newTicket: Observable<ITicket>;

  @Output('open') open: EventEmitter<boolean> = new EventEmitter();
  public ticket: NormalizedTicket;
  public ticketNotNormalized: any;
  public ticketReason: string;
  public user;
  public badge = 0;
  public msgAlert: boolean;
  public isOpen = false;
  public timeout = false;
  public phone: string;
  private ticketSubscription: Subscription;

  public canManageScheduledTicket: boolean = false;

  public newTicketDataSource: Observable<NormalizedTicket[]> = new BehaviorSubject([]);

  public closedByOperator: boolean = false; 


  constructor(
    private location: Location,
    private store: LocalStorageService,
    private apiTicketService: ApiTicketService,
    private apiTicketHistoryService: ApiTicketHistoryService,
    public dialog: MatDialog,
    private toastMessage: ToastMessage,
    private authService: AuthService
  ) {
    this.user = this.store.getItem('user');
  }

  ngOnInit() {
    this.newTicketDataSource = this.newTicket.pipe(map((ticket) => NormalizeTicket.normalizeItems([ticket])));
    this.canManageScheduledTicket = this.authService.hasPermission(['ticket.scheduled.view.all']);

    this.ticketSubscription = this.newTicket.subscribe((data: ITicket) => {
      if (this.ticket && this.ticket.id_status === Status.NEW && data.id_status === Status.ONLINE && data.id_operator !== this.user.id) {
        this.toastMessage.error('ATTENZIONE! TICKET GIA ACQUISITO', 'TICKET PRESO IN CARICO DA ALTRO OPERATORE');
        this.location.back();
      }

      const ticketClosedByUser: boolean = !this.closedByOperator && this.ticket && this.ticket.id_status === Status.ONLINE && data.id_status === Status.CLOSED && data.id_operator === this.user.id;
      if(ticketClosedByUser) {
        this.openDialogDetail();
      }

      this.ticketNotNormalized = data;
      this.ticket = NormalizeTicket.normalizeItems([data])[0];
      const initMessage = find(data.historys, item => item.id_type === HistoryTypes.INITIAL);
      this.ticketReason = (initMessage) ? initMessage.action : '';
      if (data.id_status === Status.ONLINE && data.id_operator === this.user.id) {
        this.open.next(true);
        this.isOpen = true;
        const date_acquisition: ITicketHistory = _.chain(data.historys)
                .filter(elem => elem.id_type === HistoryTypes.SYSTEM && elem.action.includes('Acquisito'))
                .orderBy(['date_time'], ['ASC'])
                .findLast()
                .value() || '';
        this.interval = setInterval(() => {
          this.timeout = moment().tz('Europe/Rome').isAfter(moment(date_acquisition.date_time).add(15, 'm'));
        }, 10000);
      }

      this.msgAlert = (data.id_operator && this.user.id !== data.id_operator && data.id_status === Status.ONLINE);
    }, (err) => {
      console.log(err);
    });
  }

  ngOnDestroy() {
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
    clearInterval(this.interval);
  }

  async reassignTicket() {
    if(!this.canManageScheduledTicket){
      return;
    }
    
    this.setUserChoise('Vuoi riassegnare il ticket schedulato?', `Ticket spostato in 'da riassegnare' da: ${this.user.userdata.name} ${this.user.userdata.surname}`, Status.SCHEDULED_UNASSIGNED);
  }

  async acquireTicket() {
    if (this.ticket.id_operator !== this.user.id) {
      this.setUserChoise('Vuoi acquisire il ticket?', `Ticket acquisito da: ${this.user.userdata.name} ${this.user.userdata.surname}`, this.ticketNotNormalized.id_status);
    }
  }

  activateChat() {
    if (this.ticket.id_status === Status.ONLINE && this.ticket.id_operator !== this.user.id) {
      this.setUserChoise('Conferma Trasferimento Ticket?', 'Trasferito ticket da Operatore: ' + this.user.userdata.name + ' ' + this.user.userdata.surname);
    } else if (this.ticket.id_status === Status.CLOSED) {
      this.setUserChoise('Conferma Riapertura Ticket?', 'Riapertura ticket da Operatore: ' + this.user.userdata.name + ' ' + this.user.userdata.surname);
    } else {
      this.setUserChoise('Conferma Presa in carico Ticket?', 'Acquisito ticket da Operatore: ' + this.user.userdata.name + ' ' + this.user.userdata.surname);
    }
  }

  private async setUserChoise(confirmMessage: string, historyMessage: string, status = Status.ONLINE) {
    const confirm = await this.toastMessage.warning(confirmMessage, '');
    if (confirm.value) {
      this.updateTicketStatus(status).pipe(
      mergeMap((data) => this.createHistoryTicketSystem(historyMessage)))
      .subscribe(() => {
        console.log('TicketHistory Subscription success');
        this.isOpen = status === Status.ONLINE;
        this.msgAlert = false;
        this.open.next(this.isOpen);  
      }, (error) => {
        this.toastMessage.error('ERRORE', (error.message && error.message === 'TICKET_ALREADY_ONLINE') ? `Ticket già gestito da un altro operatore` : `Errore generico`);
      });
    }
  }

  abortChat() {
    this.location.back();
  }

  async closeChat() {    
    this.closedByOperator = true;
    
    this.updateTicketStatus(Status.CLOSED)
    .pipe(
      tap(() => this.ticket.id_status = Status.CLOSED),
      flatMap(() => this.createHistoryTicketSystem('Chiusura ticket da Operatore: ' + this.user.userdata.name + ' ' + this.user.userdata.surname))
    )
    .subscribe(() => {
      this.toastMessage.success('Ticket chiuso!', '', false);
      this.location.back();
    });
  }

  private async refuseChat() {
    return await swal({
      title: 'Conferma Rifiuto Ticket?',
      text: 'Inserire la motivazione di questa scelta!',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      showLoaderOnConfirm: true,
      preConfirm: async(message) => {
        return new Promise((resolve) => {
          if (!message) {
            swal.showValidationError(
              'Questo messaggio è obbligatorio.'
            );
          }
          resolve();
        });
      },
    }).then(async (result) => {
      if (result.value) {
        this.isOpen = true;
        this.updateTicketStatus(Status.REFUSED)
          .subscribe(() => {
            // tslint:disable-next-line:max-line-length
            this.createHistoryTicketSystem('Rifiutato ticket da Operatore: ' + this.user.userdata.name + ' ' + this.user.userdata.surname + 'per il seguente motivo: ' + result.value)
              .subscribe(() => {
                this.toastMessage.success('Il ticket è stato rifiutato!', 'Rifiutata per: ' + result.value);
                this.location.back();
              });
          });
      }
    });
  }

  private createHistoryTicketSystem(message: string): Observable<ITicketHistory> {
    const createHistory: ITicketHistory = {
      id: null,
      id_ticket: this.ticket.id,
      id_type: HistoryTypes.SYSTEM,
      action: message,
      readed: 1
    };
    return this.apiTicketHistoryService.create(createHistory);
  }

  private updateTicketStatus(id_status: number, force?: boolean): Observable<ITicket> {
    const updateTicket: ITicket = assign({}, this.ticketNotNormalized, {
      id_status: id_status,
      id_operator: this.user.id,
    });

    return this.apiTicketService.update(updateTicket as ITicket, !!force);
  }

  openDialogDetail(): void {
    this.dialog.open(DialogCloseTicket, {
      data: { ticket: this.ticket }
    }).afterClosed().pipe(filter(Boolean)).subscribe(() => this.closeChat());
  }
}
