import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ScheduledOperatorChecker {
  static validate(control: AbstractControl): ValidationErrors | null {
    const operatorValue = control.value;
    const stateValue = (control.parent) ? control.parent.get('state').value : '';

    if (stateValue === 'Accept' && !operatorValue){
      return { operatorRequired: true };
    }
    return null;
  }
}

