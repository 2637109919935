import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from "moment";

export class MoreThan18YearsValidator {
  static validate(control: AbstractControl): ValidationErrors | null {
    const diffYears = moment().diff(control.value, "y", true);
    if (control.value && diffYears <= 18 ) {
      return {lessThan18Years: true};
    }
    return null;
  }
}
