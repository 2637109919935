import { Pipe, PipeTransform } from "@angular/core";
import { IUser } from "../../interfaces/i-user";

@Pipe({ name: "usersNamesJoin" })
export class UsersNamesJoinPipe implements PipeTransform {
  transform(value: IUser[], ...args: any[]): string {
    return value
      ? value
          .map((user) => {
            let name = user.userdata.name;
            let surname = user.userdata.surname;

            return `${name.charAt(0).toUpperCase()}${name
              .slice(1)
              .toLowerCase()} 
                ${surname.charAt(0).toUpperCase()}${surname
              .slice(1)
              .toLowerCase()}`;
          })
          .join(", ")
      : "";
  }
}
