import { UserTagService } from './../../../services/api/api-user-tag.service';
import { IUserTag } from './../../../../interfaces/i-user-tag';
import { DialogConfirmComponent } from './../utils/dialog-confirm/dialog-confirm.component';
import { Page } from './../../../../class/page';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map, filter, flatMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { ErrorMessageTranslatorService } from '../../../services/error-message-translator.service';
import { DialogUserTagComponent } from './dialog-user-tag/dialog-user-tag.component';

@Component({
    selector: 'fuse-user-tag',
    templateUrl: './user-tag.component.html',
    styleUrls: ['./user-tag.component.scss'],
})
export class UserTagComponent implements OnInit, OnDestroy {
    public rows: IUserTag[];
    public page = new Page();
    public pageSizeControl: FormControl;
    private pageSizeControlSubscription: Subscription;

    @ViewChild('myTable') table;

    constructor(
        private userTagService: UserTagService,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog,
        public toast: NotificationsService,
        private errorMessageTranslatorService: ErrorMessageTranslatorService
    ) {
        this.page.pageNumber = 0;
        this.page.size = 10;
    }

    ngOnInit() {
        this.pageSizeControl = new FormControl(10);

        this.pageSizeControlSubscription = this.pageSizeControl.valueChanges.pipe(map(Number), distinctUntilChanged()).subscribe((data) => {
            this.setPage({ offset: 0 });
        });
    }

    ngOnDestroy(): void {
        if (this.pageSizeControlSubscription) {
            this.pageSizeControlSubscription.unsubscribe();
        }
    }

    setPage(pageInfo) {
        this.spinner.show();

        this.page.pageNumber = pageInfo.offset;
        this.page.size = this.pageSizeControl.value;

        this.userTagService.apiGetUserTagsPaginated(this.page).subscribe(
            (pagedData) => {
                this.page = pagedData.page;
                this.rows = pagedData.data;
                this.spinner.hide();
            },
            () => this.spinner.hide()
        );
    }

    public create() {
        const dialogRef = this.dialog.open(DialogUserTagComponent, {
            data: {
                title: 'Aggiungi user tag',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                flatMap((data) => this.userTagService.apiCreateUserTag(data))
            )
            .subscribe(
                (result) => {
                    this.toast.success('CONFERMA', 'Nuovo user tag creato con successo!');
                    this.setPage({ offset: this.page.pageNumber });
                },
                (err) => {
                    this.toast.error('ERRORE', this.errorMessageTranslatorService.translate(err.error.message));
                }
            );
    }

    public edit(row) {
        const dialogRef = this.dialog.open(DialogUserTagComponent, {
            data: {
                title: 'Modifica user tag',
                data: row,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                flatMap((data) => this.userTagService.apiEditUserTag(data))
            )
            .subscribe(
                (data) => {
                    this.toast.success('CONFERMA', 'User tag modificato con successo!');
                    this.setPage({ offset: this.page.pageNumber });
                },
                (err) => {
                    this.toast.error('ERRORE', this.errorMessageTranslatorService.translate(err.error.message));
                }
            );
    }

    public remove(id: number) {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            data: {
                id: id,
                title: 'Cancellare user tag?',
                caption: "Eliminando questo tag verrà eliminato anche dall'elenco dei tag degli utenti che lo contengono.",
                action: 'Cancella',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                flatMap((id) => this.userTagService.apiDeleteUserTag(id))
            )
            .subscribe(
                (result) => {
                    this.toast.success('CONFERMA', 'User tag cancellato con successo');
                    this.setPage({ offset: this.page.pageNumber });
                },
                (err) => {
                    this.toast.error('ERRORE', this.errorMessageTranslatorService.translate(err.error.message));
                }
            );
    }
}
