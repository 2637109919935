import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SimpleNotificationsModule } from 'angular2-notifications';
import 'hammerjs';
import { SocketIoConfig, SocketIoModule } from 'ng-socket-io';
import { ExportmanagerComponent } from '../app/main/content/pages/exportmanager/exportmanager.component';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CustomDateFormatter } from './core/CalendarDateFormatter';
import { FuseNavigationService } from './core/components/navigation/navigation.service';
import { SharedModule } from './core/modules/shared.module';
import { FuseConfigService } from './core/services/config.service';
import { FuseSplashScreenService } from './core/services/splash-screen.service';
import { AuthGuard } from './guard/auth.guard';
import { HasPermissionGuard } from './guard/has-permission.guard';
import { IsAdminGuard } from './guard/is-admin.guard';
import { IsOperatorGuard } from './guard/is-operator.guard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { CalendarEventsComponent } from "./main/content/pages/calendar-events/calendar-events.component";
import { PagesModule } from './main/content/pages/pages.module';
import { FuseMainModule } from './main/main.module';
import { ApiLoginService } from './main/services/api/api-login.service';
import { ApiStatisticsService } from './main/services/api/api-statistics.service';
import { ApiTicketHistoryService } from './main/services/api/api-ticket-history.service';
import { ApiTicketReportService } from './main/services/api/api-ticket-report.service';
import { RegionService } from "./main/services/api/regions.service";
import { AuthService } from './main/services/auth/auth.service';
import { ErrorMessageTranslatorService } from './main/services/error-message-translator.service';
import { RollbarErrorHandler } from './main/services/errors/RollbarErrorHandler.service';
import { LocalStorageService } from './main/services/local-storage/local-storage.service';
import { SocketService } from './main/services/socket/socket.service';


registerLocaleData(localeIt);

const appRoutes: Routes = [
    {
        path: 'pages/export-report',
        canActivate: [AuthGuard, IsOperatorGuard],
        component: ExportmanagerComponent
    },
    {
        path     : 'pages/calendar',
        canActivate: [AuthGuard, IsOperatorGuard],
        component: CalendarEventsComponent
    },
    {
        path: '**',
        redirectTo: 'pages/authentication/login-2'
    }
];

const options = {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    multiplex: false,
    path: environment.ws_path,
    transports: ['websocket']
};

const wssPort = (environment.ws_port) ? ':' + environment.ws_port : '';
const config: SocketIoConfig = { url: environment.ws_url + wssPort, options: options };

@NgModule({
    declarations: [
        AppComponent,
        ExportmanagerComponent,
        CalendarEventsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        RouterModule.forRoot(appRoutes),
        SharedModule,
        TranslateModule.forRoot(),
        FuseMainModule,
        SocketIoModule.forRoot(config),
        PagesModule,
        SimpleNotificationsModule.forRoot(),
        MDBBootstrapModule.forRoot(),
        MatTooltipModule,

    ],
    providers: [
        RegionService,
        FuseSplashScreenService,
        FuseConfigService,
        FuseNavigationService,
        SocketService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        ApiLoginService,
        AuthService,
        AuthGuard,
        HasPermissionGuard,
        IsOperatorGuard,
        IsAdminGuard,
        LocalStorageService,
        ApiTicketHistoryService,
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        ApiTicketReportService,
        ApiStatisticsService,
        ErrorMessageTranslatorService,
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,

        }
        
        
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
