import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CalendarEvent, CalendarView, CalendarWeekViewBeforeRenderEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import * as moment from "moment-timezone";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Services } from "../../../../enums/ticket-services.enum";
import { Status } from "../../../../enums/ticket-status.enum";
import { ITicket } from "../../../../interfaces/i-ticket";
import { ApiTicketService } from '../../../services/api/api-ticket.service';
import { AuthService } from "../../../services/auth/auth.service";


@Component({
  selector: 'app-calendar-events',
  templateUrl: './calendar-events.component.html',
  styleUrls: ['./calendar-events.component.scss'],

})
export class CalendarEventsComponent {

  view: CalendarView = CalendarView.Month;

  viewDate: Date;

  paramsViewDate: Date = undefined;

  locale: string = 'it';

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];

  CalendarView = CalendarView;

  events$: Observable<CalendarEvent<{ ticket: ITicket }>[]>;

  activeDayIsOpen: boolean = false;

  eventRoot: string = "none";

  constructor(
    private apiTicket: ApiTicketService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { 
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let eventDate = params['eventDate'];
      if (eventDate) {
        this.viewDate = new Date(eventDate);
        this.paramsViewDate = new Date(eventDate);
        this.view = CalendarView.Week;
      } else {
        this.viewDate = new Date();
      }

      this.fetchEvents();
    });

  }

  setView(view: CalendarView) {
    this.view = view;
    this.fetchEvents();
  }

  fetchEvents(): void {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
    }[this.view];

    this.events$ = this.apiTicket.getWithCriterias({
      mapped: '0',
      id_service: Services.VIDEOCHAT_SCHEDULED.toString(),
      schedule_start: moment(getStart(this.viewDate)).format('YYYY-MM-DD') + 'T00:00:00Z',
      schedule_end: moment(getEnd(this.viewDate)).format('YYYY-MM-DD') + 'T23:59:59Z',
      id_statuses: [Status.ONLINE, Status.CLOSED, Status.SCHEDULED_ASSIGNED],
      id_operator: (!this.authService.hasPermission(['ticket.scheduled.view.all'])) 
        ? this.authService.getToken().id_user : null
    }).pipe(map((tickets: ITicket[]) => {
      const events = tickets.map((ticket) => {
        const operatorIdentifier = (ticket.operator) ? `${ticket.operator.userdata.name} ${ticket.operator.userdata.surname}` : null;
        return {
          id: ticket.id,
          title: `#${ticket.id} - ${operatorIdentifier}`,
          start: moment.tz(ticket.schedule_start.toString().replace('Z', ''), 'Europe/Rome').toDate(),
          end: moment.tz(ticket.schedule_end.toString().replace('Z', ''), 'Europe/Rome').toDate(),
          color: {
            primary: '#1e90ff',
            secondary: '#D1E8FF'
          },
          meta: {
            ticket
          },
        }
      });
      
      return events;
    }));
  }
  
  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ ticket: ITicket }>[];
  }): void {
    this.events$ = null;
    this.viewDate = date;
    this.view = CalendarView.Week;
    this.fetchEvents();
  }

  eventClicked(event: CalendarEvent<{ ticket: ITicket }>): void {
    const ticket: ITicket = event.meta.ticket;
    this.router.navigateByUrl('pages/ticket-detail/' + ticket.id);
  }

  beforeWeekViewRender(event: CalendarWeekViewBeforeRenderEvent): void {
    event.header.forEach(header => {
      if(this.paramsViewDate  && moment(header.date).isSame(moment(this.paramsViewDate), 'day')) {
        header.cssClass = 'calendar__week__column--same-day';
      }
    })
  }
}
