import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { assign, get } from "lodash";
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { merge, Observable } from 'rxjs';
import { Page } from "../../../../../class/page";
import { HistoryTypes } from '../../../../../enums/ticket-history-type.enum';
import { Status } from '../../../../../enums/ticket-status.enum';
import { ITicketHistory } from '../../../../../interfaces/i-ticket-history';
import { IUser } from '../../../../../interfaces/i-user';
import { ApiTicketHistoryService } from '../../../../services/api/api-ticket-history.service';
import { ApiTicketService } from '../../../../services/api/api-ticket.service';
import { ApiUserService } from '../../../../services/api/api-user.service';
import { LocalStorageService } from "../../../../services/local-storage/local-storage.service";
import { ScheduledOperatorChecker } from "../../../../services/MaterialValidator/ScheduledOperatorChecker";
import { ITicket } from './../../../../../interfaces/i-ticket';

@Component({
  selector: 'fuse-ticket-scheduled',
  templateUrl: './ticket-scheduled.component.html',
  styleUrls: ['./ticket-scheduled.component.scss']
})
export class TicketScheduledComponent implements OnInit{

  @Input('ticket') data: ITicket;
  public users: IUser[];

  public ticketNotNormalized: any;

  dayEvent: string= '';
  myForm: FormGroup;

  scheduleEvent: moment.Moment;

  private user;
 
  constructor(
    private apiUserService: ApiUserService,
    private spinner: NgxSpinnerService,
    private apiTicketService: ApiTicketService,
    private apiTicketHistoryService: ApiTicketHistoryService,
    private store: LocalStorageService
  ){
    this.user = this.store.getItem('user');
  }

  ngOnInit(): void {
    this.spinner.show();
    
    this.myForm = new FormGroup({
      state: new FormControl('Accept', [Validators.required]),     
      operator: new FormControl(null, [ScheduledOperatorChecker.validate]),     
      response: new FormControl(null, [Validators.required]),     
    })

    this.apiUserService.apiGetUserList(Object.assign(
      new Page(), 
      {onlyOperator: true, size: 1000 }
    )).subscribe(pagedData => {
      this.users = pagedData.data.filter((user) => !user.disabled);
      this.spinner.hide();
    });

    this.scheduleEvent = moment.tz(this.data.schedule_start.replace('Z', ''), 'Europe/Rome');
    this.dayEvent = this.scheduleEvent.toISOString();
    merge(this.myForm.get('state').valueChanges, this.myForm.get('operator').valueChanges).subscribe(() => {
      this.setMessage();
    })  
  }
  
  submitForm() {
    if(!this.myForm.valid) {
      return;
    }

    const state = this.myForm.get('state').value;
    const systemMessage = `Trasferito schedulato marked as ${state} da Operatore: ` 
      + this.user.userdata.name + ' ' + this.user.userdata.surname;
      
    if(state === 'Accept'){
      this.updateTicketStatus(Status.SCHEDULED_ASSIGNED, this.myForm.get('operator').value.id)
      .subscribe(() => {
        this.createHistoryTicket(systemMessage, HistoryTypes.SYSTEM);
        this.createHistoryTicket(this.myForm.get('response').value, HistoryTypes.AUTORESPONDER);
      })
    } else{
      this.updateTicketStatus(Status.REFUSED).subscribe(() => {
        this.createHistoryTicket(systemMessage, HistoryTypes.SYSTEM);
        this.createHistoryTicket(this.myForm.get('response').value, HistoryTypes.AUTORESPONDER);
      })
    }
  }

  setMessage(){
    const state = this.myForm.get('state').value;
    const operator = [
      get(this.myForm.get('operator').value, 'userdata.name', '-'),
      get(this.myForm.get('operator').value, 'userdata.surname', '-')
    ].join(" ");
    let textMessage = null;
    
    const date = moment(this.scheduleEvent).format('DD/MM/YYYY');
    const hoursAndMinutes = moment(this.scheduleEvent).format('HH:mm');
    
    if(state === 'Accept') {
      textMessage = [`La richiesta di servizio di interpretariato prenotata per le ore ${hoursAndMinutes} del ${date} è stata accettata.`,
      `L'interprete Lis sarà ${operator}. Ti ricordiamo che potrai collegarti 10 minuti prima dell'orario previsto.`,
      `Per maggiori informazioni, scrivere all'indirizzo info@progettosais.it`].join('\n');
    } else {
      textMessage = [`La richiesta di servizio di interpretariato prenotata per le ore ${hoursAndMinutes} del ${date} è stata rifiutata.`,
      `Per maggiori informazioni, scrivere all'indirizzo info@progettosais.it`].join('\n');
    }

    this.myForm.patchValue({response: textMessage});
    this.myForm.get('operator').updateValueAndValidity({onlySelf: true, emitEvent: false});
  }

  private createHistoryTicket(message: string, type: number): Promise<ITicketHistory> {
    const createHistory: ITicketHistory = {
      id: null,
      id_ticket: this.data.id,
      id_type: type,
      action: message,
      readed: 1
    };
    return this.apiTicketHistoryService.create(createHistory).toPromise();
  }

  private updateTicketStatus(id_status: number, operator?: number ,force?: boolean): Observable<ITicket> {
    const updateTicket: ITicket = assign({}, this.data, {
      id_status: id_status,
      id_operator: operator,
    });

    return this.apiTicketService.update(updateTicket as ITicket, !!force);
  }
}
