import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetBaseUrl } from '../helper/getBaseUrl';

@Injectable()
export class RegionService {
  private baseUrl = GetBaseUrl.baseUrl();

  constructor(
    private http: HttpClient,
  ) { }

  public getAll(): Observable<Region[]> {
    return this.http.get<Region[]>(this.baseUrl + '/region');
  }
}

export interface Region {
  id: number;
  name: string;
}
