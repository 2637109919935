import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { IUser } from '../../../../interfaces/i-user';
import { MY_FORMATS } from '../../../../type/date-format';
import { Region, RegionService } from "../../../services/api/regions.service";
import { AlphabeticOnlyValidator } from '../../../services/MaterialValidator/AlphabeticOnlyValidator';
import { EmailCustomValidator } from '../../../services/MaterialValidator/EmailCustomValidator';
import { MoreThan18YearsValidator } from "../../../services/MaterialValidator/MoreThan18YearsValidator";
import { NumericOnlyValidator } from '../../../services/MaterialValidator/NumericOnlyValidator';


@Component({
  selector: 'fuse-dialog-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class DialogProfileComponent implements OnInit {

  public modalData: IUser;
  public formGroup: FormGroup;
  public regions: Region[];
  public gender = [
    { id: 'male', name: 'Maschio'},
    { id: 'female', name: 'Femmina'}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private regionService: RegionService,
    public dialogRef: MatDialogRef<DialogProfileComponent>,
    ) {
  }

  ngOnInit() {
    this.regionService.getAll().subscribe((regions) => {
      this.regions = regions;
    });
    this.modalData = this.data.modalData;
    this.modalData.userdata.privacyaccept = this.modalData.userdata.privacyaccept || true;
    this.formGroup = new FormGroup({
      'name': new FormControl(this.modalData.userdata.name, [
        Validators.required,
        AlphabeticOnlyValidator.alphabeticOnly
      ]),
      'surname': new FormControl(this.modalData.userdata.surname, [
        Validators.required,
        AlphabeticOnlyValidator.alphabeticOnly
      ]),
      'email': new FormControl(this.modalData.userdata.email, [
        Validators.required, EmailCustomValidator.email_custom
      ]),
      'phone': new FormControl(this.modalData.userdata.phone, [
        Validators.required, NumericOnlyValidator.numericOnly
      ]),
      'privacyaccept': new FormControl({value: this.modalData.userdata.privacyaccept, disabled: true}),
      'id_region': new FormControl(this.modalData.userdata.id_region, [
        Validators.required
      ]),
      'born_date': new FormControl(this.modalData.userdata.born_date, [
        Validators.required,
        MoreThan18YearsValidator.validate
      ]),
    });
  }

  onYesClick() {
    this.dialogRef.close(Object.assign(this.modalData, {userdata: this.formGroup.value}));
  }

}
