import { Injectable } from "@angular/core";

import * as _ from "lodash";
import * as moment from "moment";
import { Services } from "../../../enums/ticket-services.enum";
import { ITicket } from "../../../interfaces/i-ticket";
import { ITicketHistory } from "../../../interfaces/i-ticket-history";
import { ITicketReport } from "../../../interfaces/i-ticket-report";
import { get } from "lodash";

@Injectable()
export class NormalizeTicket {
  public static normalizeItems(ticket: ITicket[]): NormalizedTicket[] {
    return _.map(ticket, (item: ITicket) => {
      const closed_at =
        item.status &&
        (item.status.status === "CLOSED" || item.status.status === "REFUSED")
          ? _.chain(item.historys)
              .filter((elem) => elem.type.type === "SYSTEM")
              .orderBy(["date_time"], ["ASC"])
              .findLast()
              .value()
          : "";

      return {
        id: item.id,
        id_service: item.id_service,
        id_status: item.id_status,
        service: item.service.service,
        status: item.status ? item.status.status : item.status.status,
        id_operator: item.id_operator,
        id_user: item.id_user,
        id_user_unknown: item.id_user_unknown,
        id_category: item.id_category,
        operator_name: item.operator ? item.operator.userdata.name : "",
        operator_surname: item.operator ? item.operator.userdata.surname : "",
        user_name: item.user
          ? item.user.userdata.name
          : item.userUnknown
          ? "UNKNOWN"
          : "",
        user_surname: item.user
          ? item.user.userdata.surname
          : item.userUnknown
          ? item.userUnknown.email
            ? `(${item.userUnknown.email})`
            : `(${item.userUnknown.phone})`
          : "",
        user_privacy: item.user ? item.user.userdata.privacyaccept : 0,
        category: item.category ? item.category.category : "UNCATEGORIZED",
        date_time: item.date_time, // moment(item.date_time).format('DD/MM/YYYY HH:mm'),
        schedule_start: item.schedule_start,
        duration: item.duration,
        note: item.note,
        historys: item.historys,
        reports: item.reports,
        closed_at: closed_at
          ? moment.utc(closed_at.date_time).format("DD/MM/YYYY HH:mm")
          : undefined,
        unreaded_messages: item.unreaded_messages || 0,
        user_disabled: !!item.user ? item.user.disabled : false,
        user_region:
          !!item.user && !!item.user.userdata.region
            ? item.user.userdata.region.name
            : "",
        typology:
          item.id_service === Services.VIDEOCHAT_SCHEDULED
            ? "Schedulato"
            : "Ondemand",
        mode: item.schedulationDetail
          ? item.schedulationDetail.is_remote
            ? "A distanza"
            : "In presenza"
          : "-",
        city: get(item, "schedulationDetail.city"),
        address: get(item, "schedulationDetail.address"),
        reason: get(item, "schedulationDetail.reason"),
        noteDetail: get(item, "schedulationDetail.note"),
      };
    });
  }
}

export interface NormalizedTicket {
  id: number;
  id_service: number;
  id_status: number;
  service: string;
  status: string;
  id_operator: number;
  id_user: number;
  id_user_unknown: number;
  id_category: number;
  operator_name: string;
  operator_surname: string;
  user_name: string;
  user_surname: string;
  user_privacy: number;
  category: string;
  date_time: Date;
  historys: ITicketHistory[];
  reports: ITicketReport[];
  closed_at: Date;
  unreaded_messages: number;
  user_disabled: boolean;
  user_region: string;
  schedule?: Date;
  duration?: number;
  note?: string;
  typology?: string;
  mode?: string;
  city?: string;
  address?: string;
  reason?: string;
  noteDetail?: string;
}
