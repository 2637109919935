import { IUserTag } from './../../../interfaces/i-user-tag';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPageRequest } from '../../../interfaces/i-page-request';
import { IUserTagsResponse } from '../../../interfaces/i-user-tags-response';
import { GetBaseUrl } from '../helper/getBaseUrl';
import { UtilsService } from './utils.service';

@Injectable()
export class UserTagService {
    private baseUrl = GetBaseUrl.baseUrl();

    constructor(private http: HttpClient) {}

    public apiGetUserTags(request: IPageRequest): Observable<IUserTag[]> {
        let queryParams: HttpParams = UtilsService.buildQueryParams(request);

        return this.http.get<IUserTag[]>(`${this.baseUrl}/user-tag`, {
            params: queryParams,
        });
    }

    public apiGetUserTagsPaginated(request: IPageRequest): Observable<IUserTagsResponse> {
        let queryParams: HttpParams = UtilsService.buildQueryParams(request);

        return this.http.get<IUserTagsResponse>(`${this.baseUrl}/user-tag`, {
            params: queryParams,
        });
    }

    public apiCreateUserTag(newUserTag: IUserTag): Observable<IUserTag> {
        return this.http.post<IUserTag>(`${this.baseUrl}/user-tag`, newUserTag);
    }

    public apiEditUserTag(userTag: IUserTag): Observable<IUserTag> {
        return this.http.put<IUserTag>(`${this.baseUrl}/user-tag`, userTag);
    }

    public apiDeleteUserTag(id: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.baseUrl}/user-tag/${id}`);
    }
}
