import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { Status } from '../../../../../enums/ticket-status.enum';
import { ITicket } from '../../../../../interfaces/i-ticket';
import { AuthService } from "../../../../services/auth/auth.service";

@Component({
  selector: 'fuse-ticket-videochat',
  templateUrl: './ticket-videochat.component.html',
  styleUrls: ['./ticket-videochat.component.scss']
})
export class TicketVideochatComponent implements OnInit, OnDestroy {
  @Input('ticket') data: Observable<ITicket>;
  public ticket: ITicket;
  private ticketSubscription: Subscription;
  public videochatRunnable = false;
  public roomUrl: string = null;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.ticketSubscription = this.data.subscribe((item: ITicket) => {
      this.ticket = item;
      this.videochatRunnable = !_.includes([Status.REFUSED, Status.CLOSED], this.ticket.id_status);
      this.roomUrl = item.operator_room_url;
    }, (err) => {
      console.error(err);
    });
  }

  ngOnDestroy(): void {
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
  }
}
